<template>
  <v-footer color="" >
    <v-row  no-gutters>
      <v-col class="d-flex justify-end">
      <v-btn
        v-for="(link, index) in links"
        :key="index"
        text
        rounded
        :href="link.href"
        color="grey"
        target="_blank"
        class="my-2"
      >
        {{ link.label }}
      </v-btn>
      </v-col>
    </v-row>
  </v-footer>
</template>

<script>
import { mapActions } from "vuex";
export default {
  name: "Footer",

  data: () => ({
    link : {},
    links: [],
  }),
   methods: {
     ...mapActions([
      "get_car_text_setting",
    ]),
    prepare_car_text_setting: function () {
      this.get_car_text_setting().then((response) => {
       this.link = response
       this.links = [
      {
        label: this.link.privacy_link_text,
        href: this.link.privacy_url
      },
      {
        label: this.link.imprint_link_text,
        href: this.link.imprint_url 
      }
    ]
      });
    },
  },
  async mounted() {
    await this.prepare_car_text_setting();
  },
};
</script>
