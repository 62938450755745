<template>
  <v-stepper v-model="e1" class="elevation-0">
    <v-stepper-header>
      <v-stepper-step :complete="e1 > 1" step="1"> {{ car_text_setting_data.step_1_text }} </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 2" step="2"> {{ car_text_setting_data.step_2_text }} </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step :complete="e1 > 3" step="3"> {{ car_text_setting_data.step_3_text }} </v-stepper-step>

      <v-divider></v-divider>

      <v-stepper-step step="4"> {{ car_text_setting_data.step_4_text }}</v-stepper-step>
    </v-stepper-header>

    <!-- 
      This step is for brand selection
      Once brand selected it will return brand-data
      and switch to step 2
     -->
    <v-stepper-items>
      <v-stepper-content step="1">
        <v-card class="mb-12" color=" lighten-1" flat>
          <Step1
            @allow-step-two="allow_step_two"
            @step1-data="get_step1_data"
            :car_text_setting_data="car_text_setting_data"
          />
        </v-card>
      </v-stepper-content>

      <!-- 
        This step is for different car filters.
        It will get brand_data from step 1 and use it for filter
        car type
        three selection input
        car list selection
        once user select all those data,
        it will return one object containing all data.
        and switch to step 3
       -->
      <v-stepper-content step="2">
        <v-card class="mb-12" color=" lighten-1" flat>
          <Step2
            @allow-step-three="allow_step_three"
            @step2-data="get_step2_data"
            :set_step1_data="step1_data"
            :car_text_setting_data="car_text_setting_data"
          />
          <v-card class="d-flex justify-center" flat>
            <v-btn class="ml-2" @click="e1 = 1"> {{ car_text_setting_data.back_button_step_2_text }} </v-btn>
          </v-card>
        </v-card>
      </v-stepper-content>

      <!-- 
        This is step is for Booking date and slot selection
        It will get get booking_data from step 2 and use it further for booking
        It will also get selected car image and information to show before date and slot selection
        Once user select date and slot,
        we will merge those to booking-data and return all data
        and switch to step 4
       -->
      <v-stepper-content step="3">
        <v-card class="mb-12" color=" lighten-1" flat>
          <Step3
            @allow-booking-form="allow_booking_form"
            @step3-data="get_step3_data"
            @book_model_data="get_book_model_data"
            :set_step2_data="step2_data"
            :car_text_setting_data="car_text_setting_data"
            :unset_slots="new Date()"
          />
           <v-card class="d-flex justify-center" flat>
            <v-btn class="ml-2" @click="e1 = 2"> {{ car_text_setting_data.back_button_step_3_text}} </v-btn>
          </v-card>
        </v-card>
      </v-stepper-content>

      <!-- 
        This is step for booking form
        it will get all the booking-data from previous steps
        it will show one booking information form
       -->
      <v-stepper-content step="4">
        <v-card class="mb-12" color=" lighten-1" flat>
          <BookingForm
            v-if="!submit_status"
            @booking-submit="submit_booking"
            @booking-back="e1 = 3"
            :set_step3_data="step3_data"
            :book_model_data="book_model_data"
            :car_text_setting_data="car_text_setting_data"
          />
        </v-card>
        <v-card class="d-flex justify-center flat" height="500" v-if="submit_status">
          <v-card class="align-self-center" flat>
            <v-alert prominent type="success" color="primary">
             {{ car_text_setting_data.confirmation_text }}
            </v-alert>
            <v-btn href="/" class="center"> {{ car_text_setting_data.restart_button_text }} </v-btn>
          </v-card>
        </v-card>
      </v-stepper-content>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import BookingForm from "./BookingForm";
import { mapActions } from "vuex";

export default {
  name: "Stepper",
  components: {
    Step1,
    Step2,
    Step3,
    BookingForm
  },
  methods: {
     ...mapActions([
      "get_car_text_setting",
    ]),
    prepare_car_text_setting: async function () {
      await this.get_car_text_setting().then((response) => {
       this.car_text_setting_data = response
      });
      const box = document.querySelector('#privacy_link');
      box.addEventListener('click', function (e) {
        e.stopPropagation();
      });
    },
    allow_step_two: function (e) {
      this.step_two = e;
      this.e1 = 2;
      window.scrollTo(0, 0);
    },
    allow_step_three: function (e) {
      this.step_three = e;
      this.e1 = 3;
      window.scrollTo(0, 0);
    },
    allow_booking_form: function (e) {
      this.step_three = e;
      this.e1 = 4;
      window.scrollTo(0, 0);
    },
    get_step3_data: function (data) {
      this.step3_data = data;
    },
    get_book_model_data: function (data) {
      this.book_model_data = data;
    },
    get_step2_data: function (data) {
      this.step2_data = data;
    },
    get_step1_data: function (data) {
      this.step1_data = data;
    },
    showStepOne() {
      this.e1 = 1;
      window.scrollTo(0, 0);
    },
    showStepTwo() {
      this.e1 = 2;
      window.scrollTo(0, 0);
    },
    showStepThree() {
      this.e1 = 3;
      window.scrollTo(0, 0);
    },
    showBookingForm() {
      this.e1 = 4;
      window.scrollTo(0, 0);
    },
    submit_booking: function (result) {
      if (result) {
        this.submit_status=true;
        this.e1 = 4;
      } else {
        alert("Bitte versuche es erneut");
      }
    },
  },
  async mounted() {
    await this.prepare_car_text_setting();
  },
  data() {
    return {
      e1: 1,
      step_two: true,
      step_three: true,
      booking_form: true,
      step1_data: null,
      step2_data: null,
      step3_data: null,
      book_model_data: null,
      submit_status: false,
      car_text_setting_data :  {}
    };
  },
};
</script>

<style scoped>
.v-stepper--is-booted .v-stepper__content, .v-stepper--is-booted .v-stepper__wrapper {
    /* transition: 0.6s cubic-bezier(0.18, 0.89, 0.32, 1.28); */
    transition: 0.5s ease-in-out;
}
</style>
