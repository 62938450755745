import Vue from 'vue'
import Vuex from 'vuex'

import { car_details } from '../api';

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  mutations: {
  },
  actions: {
    async book_appointment({ dispatch }, post_data) {
        return car_details.book_appointment(post_data)
            .then(
                response => {
                    return response;
                }
            ).catch(
                (response) => {
                    dispatch('error', response, { root: true });
                }
            );
    },
    async get_details({ dispatch }, car_type_details) {
        return car_details.get_details(car_type_details)
            .then(
                response => {
                    return response;
                }
            ).catch(
                (response) => {
                    dispatch('error', response, { root: true });
                }
            );
    },
    async get_car_details({ dispatch }, car_details_data) {
        return car_details.get_car_details(car_details_data)
            .then(
                response => {
                    return response;
                }
            ).catch(
                (response) => {
                    dispatch('error', response, { root: true });
                }
            );
    },
    async get_available_slot({ dispatch }, data) {
        return car_details.get_available_slot(data)
            .then(
                response => {
                    return response;
                }
            ).catch(
                (response) => {
                    dispatch('error', response, { root: true });
                }
            );
    },
    async get_car_type_model_details({ dispatch }) {
      return car_details.get_car_type_model_details()
          .then(
              response => {
                  return response;
              },
              error => {
                  setTimeout(() => {
                      dispatch('error', error, { root: true });
                  });
                  return Promise.reject(error);
              }
          );
    },
    async get_location({ dispatch }, car_data) {
      return car_details.get_location(car_data)
          .then(
              response => {
                  return response;
              },
              error => {
                  setTimeout(() => {
                      dispatch('error', error, { root: true });
                  });
                  return Promise.reject(error);
              }
          );
    },
    async get_car_text_setting({ dispatch }) {
        return car_details.get_car_text_setting()
            .then(
                response => {
                    return response;
                },
                error => {
                    setTimeout(() => {
                        dispatch('error', error, { root: true });
                    });
                    return Promise.reject(error);
                }
            );
      },
  },
  modules: {
  }
})
